import type { JsonLD } from "nuxt-jsonld/dist/types/index.d";
import dayjs from "dayjs";

export default function useStaticSchema(): JsonLD {

  const parentOrganization: string = 'Ringier Axel Springer';
  const legalName: string = 'Lendi spółka z ograniczoną odpowiedzialnością';
  const slogan: string = 'Najlepszy sposób na najtańszy kredyt'
  const logo: string = 'https://www.lendi.pl/jak-wybrac-najtanszy-kredyt/wp-content/uploads/2021/06/lendi-logo.png';

  const founders: JsonLD[] = [
    {
      "@type": "Person",
      "givenName": "Michał",
      "familyName": "Petters",
      "jobTitle": "Co-founder of Lendi",
      "sameAs": ["https://pl.linkedin.com/in/michal-petters-69585925"],
      "@id": "https://www.lendi.pl/#/schema/Person/michal-petters"
    },
    {
      "@type": "Person",
      "givenName": "Piotr",
      "familyName": "Stempiński",
      "jobTitle": "Co-founder of Lendi",
      "sameAs": ["https://pl.linkedin.com/in/piotr-stempi%C5%84ski-88883b25"],
      "@id": "https://www.lendi.pl/#/schema/Person/piotr-stempinski"
    },
    {
      "@type": "Person",
      "givenName": "Sławomir",
      "familyName": "Rykalski",
      "jobTitle": "Co-founder of Lendi",
      "sameAs": ["https://pl.linkedin.com/in/s%C5%82awomir-rykalski-78b451115"],
      "@id": "https://www.lendi.pl/#/schema/Person/slawomir-rykalski"
    },
  ];

  const schemaImages: JsonLD[] = [
    {
      '@type': 'ImageObject',
      url: 'https://www.lendi.pl/jak-wybrac-najtanszy-kredyt/wp-content/uploads/2021/06/lendi-logo.png',
      contentUrl: 'https://www.lendi.pl/jak-wybrac-najtanszy-kredyt/wp-content/uploads/2021/06/lendi-logo.png',
      caption: 'Lendi Logo',
      width: 480,
      height: 170,
      '@id': 'https://www.lendi.pl/#/schema/ImageObject/1',
    },
    {
      '@type': 'ImageObject',
      url: 'https://www.lendi.pl/imgs/Lendi-logo.jpg',
      contentUrl: 'https://www.lendi.pl/imgs/Lendi-logo.jpg',
      caption: 'Lendi Thumbnail',
      width: 720,
      height: 720,
      '@id': 'https://www.lendi.pl/#/schema/ImageObject/2',
    }
  ];

  const parentOrganizations: JsonLD = [
    {
      "@type": "Organization",
      "name": "Ringier Axel Springer Polska",
      "url": "https://ringieraxelspringer.pl/",
      "sameAs": [
        "https://pl.wikipedia.org/wiki/Ringier_Axel_Springer_Polska",
        "https://www.google.com/search?kgmid=/g/11vdhfysg"
      ],
      "@id": "https://www.lendi.pl/#/schema/Organization/Ringier"
    },
    {
      "@type": "Organization",
      "name": "MORIZON-GRATKA",
      "alternateName": "GRUPA MORIZON-GRATKA SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ",
      "url": "https://morizon-gratka.pl/",
      "sameAs": [
        "https://pl.linkedin.com/company/grupa-morizon-gratka",
        "https://www.google.com/search?kgmid=/g/11fy2dw84s"
      ],
      "@id": "https://www.lendi.pl/#/schema/Organization/Morizon"
    }
  ]

  function lendiOrganization(url?: string): JsonLD {
    const newUrl = 'https://www.lendi.pl' + (url ?? '/');
    return ({
      "@type": "Organization",
      "name": "Lendi",
      "legalName": "Lendi spółka z ograniczoną odpowiedzialnością",
      "alternateName": "Lendi",
      "description": "Pośrednik finansowy",
      "url": newUrl,
      "image": {
        "@type": "ImageObject",
        "url": "https://www.lendi.pl/jak-wybrac-najtanszy-kredyt/wp-content/uploads/2021/06/lendi-logo.png",
        "width": 480,
        "height": 170,
        "@id": "https://www.lendi.pl/#/schema/ImageObject/1",
        "contentUrl": "https://www.lendi.pl/jak-wybrac-najtanszy-kredyt/wp-content/uploads/2021/06/lendi-logo.png",
        "caption": "Lendi Logo",
      },
      "email": "kontakt@lendi.pl",
      "telephone": "+48 775 503 930",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Zielonogórska 31/lok. 1.1",
        "postalCode": "71-084",
        "addressLocality": "Szczecin",
        "addressRegion": "Zachodniopomorskie",
        "addressCountry": "PL",
        "@id": "https://www.lendi.pl/#/schema/Address/Organization/1"
      },
      "contactPoint": [{
        "@type": "ContactPoint",
        "telephone": "+48 775 503 930",
        "contactType": "Customer Support",
        "email": "kontakt@lendi.pl"
      }],
      "award": [
        "Grand Press Economy 2014",
        "Najlepszy dziennikarz ekonomiczny w konkursie im. Władysława Grabskiego, organizowanym przez NBP w 2013 roku",
        "Laureat nagrody specjalnej UOKiK w 2012 roku",
        "Nagroda Dziennikarstwa Ekonomicznego Press Club 2016",
        "Heros Rynku Kapitałowego w kategorii dziennikarz ekonomiczny 2014 i 2019"
      ],
      "sameAs": [
        "https://www.facebook.com/lendi.polska",
        "https://www.linkedin.com/company/lendi-pl",
        "https://www.youtube.com/channel/UCHSedapS_VOz68xckW1D0bA",
        "https://business.google.com/n/12196338267548984818/profile?fid=16325767616429140274"
      ],
      "employee": [
        { "@id": "https://www.lendi.pl/#/schema/Person/michal-petters" },
        { "@id": "https://www.lendi.pl/#/schema/Person/piotr-stempinski" },
        { "@id": "https://www.lendi.pl/#/schema/Person/slawomir-rykalski" }
      ],
      "founder": { "@id": "https://www.lendi.pl/#/schema/Person/piotr-stempinski" },
      "parentOrganization": { "@id": "https://www.lendi.pl/#/schema/Organization/Ringier" },
      "memberOf": { "@id": "https://www.lendi.pl/#/schema/Organization/Morizon" },
      "@id": "https://www.lendi.pl/#/schema/Organization/1"
    })
  };

  const lendiPostalAddress: JsonLD = {
    "@type": "PostalAddress",
    "streetAddress": "Zielonogórska 31/lok. 1.1",
    "postalCode": "71-084",
    "addressLocality": "Szczecin",
    "addressRegion": "Zachodniopomorskie",
    "addressCountry": "PL",
    "@id": "https://www.lendi.pl/#/schema/Address/Organization/1"
  };

  const lendiWebsite: JsonLD = {
    '@type': 'WebSite',
    url: 'https://www.lendi.pl/',
    name: 'Lendi',
    alternateName: 'lendi.pl',
    publisher: { '@id': 'https://www.lendi.pl/#/schema/Organization/1' },
    '@id': 'https://www.lendi.pl/#/schema/WebSite/1',
  };

  function currentWebPage(url?: string): JsonLD {
    const newUrl = 'https://www.lendi.pl' + (url ?? '/');
    const yesterdayDate = dayjs().subtract(1, 'day').startOf('day').toISOString();
    return {
      '@type': 'WebPage',
      url: newUrl,
      name: 'Informacje o firmie Lendi',
      description: 'Lendi to najszybciej rosnący broker finansowy na rynku, z siecią placówek w całej Polsce i zespołem ponad 1500 ekspertów.',
      isPartOf: { '@id': 'https://www.lendi.pl/#/schema/WebSite/1' },
      dateModified: yesterdayDate,
      primaryImageOfPage: { '@id': 'https://www.lendi.pl/#/schema/ImageObject/1' },
      '@id': 'https://www.lendi.pl/#/schema/WebPage/1',
    };
  }

  const review: JsonLD = {
    '@type': 'Review',
    author: {
      '@type': 'Person',
      name: 'Maciej Samcik',
      url: 'https://subiektywnieofinansach.pl/',
      jobTitle: 'Bloger Finansowy',
      award: [
        'Grand Press Economy 2014',
        'Najlepszy dziennikarz ekonomiczny w konkursie im. Władysława Grabskiego, organizowanym przez NBP w 2013 roku',
        'Laureat nagrody specjalnej UOKiK w 2012 roku',
        'Nagroda Dziennikarstwa Ekonomicznego Press Club 2016',
        'Heros Rynku Kapitałowego w kategorii dziennikarz ekonomiczny 2014 i 2019',
      ],
    },
    reviewBody:
      'To nie jest czysta porównywarka, lecz system, który stara się uwzględniać przy porównywaniu ofert autentyczny interes klienta.',
  }

  const fundingLocation: JsonLD = {
    '@type': 'Place',
    address: 'Szczecin, Polska',
  }

  const brand: JsonLD = {
    '@type': 'Brand',
    name: 'Lendi',
    logo: 'https://www.lendi.pl/jak-wybrac-najtanszy-kredyt/wp-content/uploads/2021/06/lendi-logo.png',
  }

  const sameAs: JsonLD = [
    'https://www.facebook.com/lendi.polska',
    'https://www.linkedin.com/company/lendi-pl/?originalSubdomain=pl/',
  ]

  const provider: JsonLD = {
    '@type': 'Organization',
    name: 'Lendi',
    description: 'Pośrednik finansowy',
    url: 'https://www.lendi.pl',
    legalName: legalName,
    parentOrganization: parentOrganization,
    slogan: slogan,
    logo: logo,
    review: review,
    brand: brand,
    foundingLocation: fundingLocation,
    sameAs: sameAs,
    founder: founders
  }

  function addAreaServedCity(city: string): JsonLD {
    return {
      '@type': 'City',
      name: city,
    }
  }

  function addAreaServedCountry(country: string = 'Polska'): JsonLD {
    return {
      '@type': 'Country',
      name: country
    }
  }

  return {
    founders,
    provider,
    addAreaServedCity,
    addAreaServedCountry,
    schemaImages,
    lendiOrganization,
    lendiPostalAddress,
    parentOrganizations,
    lendiWebsite,
    currentWebPage,
  };
}
